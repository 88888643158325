// eslint-disable-next-line import/prefer-default-export
export const retailNowHero = {
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};

export const retailNowWhiteGlove = {
  mainTitle: "Powerful tech backed by 5-star service",
  title: "Because business is personal",
  content:
    "Running a retail business is like doing a dozen jobs at once. We’re here to help. At SpotOn, we create innovative software and point-of-sale tools that let you do things a better way—like sell online and in-store from a single catalog. But we also offer the in-person service and support you deserve to get the most from those tools. Come talk to us at Retail Now and see for yourself.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};
export const retailNowBoothData = {
  boothInfo: "Learn more at booth 324",
  boothComments:
    "Find SpotOn at booth 324 to learn more about SpotOn’s innovative tech that’s helping local business compete and win—as well our dealer partnership opportunities.",
  boothImg: "retail-now.png",
  boothLocation: "Gaylord Opryland Nashville, Tennessee",
  boothDirections: "2800 Opryland Dr, Nashville, TN 37214",
  boothMap:
    "https://www.google.com/maps/place/Gaylord+Opryland+Resort+%26+Convention+Center/@36.2125575,-86.6972765,15z/data=!4m2!3m1!1s0x0:0x3ac9507e8aa057bb?sa=X&ved=2ahUKEwjxt_uR6OXxAhVBWK0KHVBiBUkQ_BIwHnoECFUQBQ",
};

export const retailNowLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle:
        "How to Streamline Your Operations and Improve the Guest Experience",
      blockSubTitle:
        "Catch our educational session where you’ll get first hand insight into everything you need to know about streamlining your operations and improving your guest experience with technology.",
      blockList: [
        "Kevin Bryla, \nHead of Customer Experience for SpotOn",
        "Hillary Holmes, \nGM of Von Elrod’s Beer Hall & Kitchen",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/events/demo",
      },
    },
  ],
};

export const showRotarySection = {
  title: "SpotOn across the nation",
  subtext:
    "Our team is on the road to connect with small and midsize businesses operators and learn how we can better serve you.",

  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  // images: [],
  images: [
    {
      image: "1-image.png",
      className: "image-wrapper--1",
      thumbnail: "1-thumbnail.png",
    },
    {
      image: "2-image.png",
      className: "image-wrapper--2",
      thumbnail: "2-thumbnail.png",
    },
    {
      image: "3-image.png",
      className: "image-wrapper--3",
      thumbnail: "3-thumbnail.png",
    },
    {
      image: "4-image.png",
      className: "image-wrapper--4",
      thumbnail: "4-thumbnail.png",
    },
    {
      image: "5-image.png",
      className: "image-wrapper--5",
      thumbnail: "5-thumbnail.png",
    },
    {
      className: "image-wrapper--6",
      thumbnail: "6-thumbnail.png",
      location: "Activmeals",
    },
  ],
};
